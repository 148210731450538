(function (win, doc) {
  function createAstMarketingStorage() {
    if (win.AST_Persistence) {
      const qs = win.AST_Persistence.buildQueryStringFromLocalStorage();

      // create a local storage item that contains the parameters to append
      // to the URLs.
      localStorage.setItem('brand_ast_marketing_params', qs);
    }
  }

  // Locates all links containing https://alle.com/search and appends the marketing
  // params saved into local storage
  function addMarketingParams() {
    const alleLinks = doc.querySelectorAll(
      'a[href*="https://alle.com/search"]'
    );
    const ls = localStorage.getItem('brand_ast_marketing_params');

    const marketingParamsArray =
      ls &&
      ls.split('&').reduce((acc, item) => {
        const parts = item.split('=');
        if (parts[0] && parts[1]) {
          acc.push({ key: [parts[0]], value: parts[1] });
        }
        return acc;
      }, []);

    if (marketingParamsArray.length) {
      alleLinks.forEach(item => {
        const url = new URL(item.href);
        const searchParams = new URLSearchParams(url.search);
        marketingParamsArray.forEach(param => {
          searchParams.set(param.key, param.value);
        });
        url.search = searchParams;
        item.href = url.href;
      });
    }
  }

  function init() {
    doc
      .querySelector('body')
      .addEventListener('Persistence_Ready', function () {
        console.log('persist ready');
        createAstMarketingStorage();
        addMarketingParams();
      });

    doc
      .querySelector('body')
      .addEventListener('Persistence_Updated', function () {
        console.log('persist updated');
        createAstMarketingStorage();
        addMarketingParams();
      });
  }

  // When DOM is loaded call initialize.
  if (doc.readyState === 'loading') {
    doc.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})(window, document);
