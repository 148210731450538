const _temp0 = require("./components/carousel/_carousel.scss");

const _temp1 = require("./components/container-isi/_container-isi.scss");

const _temp2 = require("./components/embed/_plyr.scss");

const _temp3 = require("./components/footer/_footer.scss");

const _temp4 = require("./components/header/_header.scss");
const _temp5 = require("./components/header/_nav.scss");

const _temp6 = require("./components/teaser/_teaser.scss");

module.exports = {
  "carousel":   {
    "_carousel": _temp0
  },
  "container-isi":   {
    "_container-isi": _temp1
  },
  "embed":   {
    "_plyr": _temp2
  },
  "footer":   {
    "_footer": _temp3
  },
  "header":   {
    "_header": _temp4,
    "_nav": _temp5
  },
  "teaser":   {
    "_teaser": _temp6
  }
}