// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

import './fas';

// importing alle redirect js as a part of Find a provider's alle-redirects
import './alle-redirect';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  // when window is resized, resize the carousel heights
  const handleCarousels = () => {
    const carouselInstances = window._tnsInstances;
    if (carouselInstances) {
      const instanceKeys = Object.keys(carouselInstances);
      if (instanceKeys?.length) {
        window.addEventListener('resize', () => {
          instanceKeys.forEach(key => {
            const curInst = window._tnsInstances[key];
            curInst?.updateSliderHeight?.();
          });
        });
      }
    }
  };

  const handleVideo = videoId => {
    if (!videoId) {
      return;
    }

    try {
      const video = document.getElementById(videoId) as HTMLElement;
      if (video) {
        const observer = new IntersectionObserver(
          entries => {
            entries.map(entry => {
              const intersecting = entry.isIntersecting;
              if (intersecting) {
                window?._plyrInstances[videoId]?.decreaseVolume(100);
                window?._plyrInstances[videoId]?.play();
              } else {
                window?._plyrInstances[videoId]?.pause();
              }
            });
          },
          { threshold: 0.6 }
        );

        observer.observe(video);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const init = () => {
    if (window.Bus) {
      // listen to events here
    }

    handleVideo('gumminess-video');
    handleVideo('implant-animation');
    handleCarousels();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
