(() => {
  const CONSTANTS = {
    FAPSearchParam: 'location',
  };

  // form submission and redirection for FAS form
  const appendFASFormEvents = fasContainer => {
    const form = fasContainer?.querySelector('form');
    const zipInp = fasContainer?.querySelector(
      '.cmp-form-text__text'
    ) as HTMLInputElement;

    if (zipInp) {
      form?.addEventListener('submit', e => {
        e.preventDefault();

        // Need to grab fasUrl later due to race condition with alle-redirect.js
        let fasUrl = form
          ?.closest('.natrelle__fas-inner-container')
          ?.querySelector('.fas-url a')
          ?.getAttribute('href');
        

        if (fasUrl && !form.querySelector('.error') && zipInp.value) {
          // making sure that there are no duplicates in query params
          fasUrl = fasUrl.replace(`&${CONSTANTS.FAPSearchParam}=`, '');
          fasUrl = fasUrl.replace(`${CONSTANTS.FAPSearchParam}=`, '');

          const processedFasURL = new URL(fasUrl);
          processedFasURL.searchParams.append(
            CONSTANTS.FAPSearchParam,
            zipInp.value
          );

          const ghostLink = document.createElement('a');
          ghostLink.target = '_blank';
          ghostLink.href = processedFasURL.href;
          ghostLink.click();
        } else if (!zipInp.value && window.Event) {
          // the default validation happens only on change event and not on submit.
          // So, creating and dispatching a change event on input field, making it to invoke the validation.
          const event = new Event('change');
          zipInp.dispatchEvent(event);
        }
      });
    }
  };

  // Find a specialist input should only allow numbers to be typed.
  const handleFASWidget = () => {
    //FAS widget and FAS & get savings related
    const zipInputs = document?.querySelectorAll(
      'input[name="zip"]'
    ) as NodeList;

    if (zipInputs.length) {
      zipInputs.forEach(zipInp => {
        const form = (<HTMLInputElement>zipInp).closest('form');
        let checkTimer;
        // allow only numbers when input field is edited
        (<HTMLInputElement>zipInp)?.addEventListener('input', function () {
          (<HTMLInputElement>zipInp).value = (<HTMLInputElement>(
            zipInp
          )).value.replace(/[^0-9.]/g, '');
          if (checkTimer) {
            clearTimeout(checkTimer);
          }

          checkTimer = setTimeout(() => {
            const errorWrapper = (<HTMLInputElement>zipInp).closest(
              '.has-error'
            );
            if (errorWrapper) {
              form?.classList.add('form-has-error');
            } else {
              form?.classList.remove('form-has-error');
            }
          }, 400);
        });
      });
    }

    //FAS widget and FAS & get savings related
    const fasContainers = document?.querySelectorAll(
      '.natrelle__fas-inner-container'
    );

    fasContainers.forEach(fasContainer => {
      appendFASFormEvents(fasContainer);
    });
  };

  const init = () => {
    handleFASWidget();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
